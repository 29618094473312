$(() => {
  $(document).on('click', '.js-xp:not(.js-fancyboxVideo):not([href])', e => {
    /* Open simpleTextPopin when clicking an XP that is not a fancyboxVideo or a regular anchor */
    simpleTextPopin.open($(e.currentTarget).find('.simpleTextPopin'));
  });
});

//gestion lightbox video
const videoPopin = new FancyboxView({
  selector: '.js-fancyboxVideo',
  fancyType: 'custom',
  options: {
    infobar: false,
    toolbar: true,
    buttons: ['close', 'fullScreen'],
    iframe: {
      tpl: '<iframe id="fancybox-frame{rnd}" name="fancybox-frame{rnd}" class="fancybox-iframe" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" allow="autoplay" src=""></iframe>'
    }
  }
});
