$(() => {
  $(document).on('click', '.js-cpBusiness .js-lge', () => {
    /* Tracking event when clicking on a little encart from the cpBusiness block */
    Tracking.dataLayerPush({
      clickCode: 'clickBusinessBlock',
      gaCategory: PAGE_SCRIPTS.page_template,
      gaAction: 'Click on discover button',
      gaLabel: PRODUCT_SCRIPTS.WORDING_PRODUCT.accommodation_btn,
      gaValue: 1
    });
  });
});